import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Flex, Link, Text } from 'rebass';
import { createGlobalStyle } from 'styled-components';
import AnimatedContainer from '../components/AnimatedContainer';
import Banner from '../components/Banner';
import ContactItem from '../components/ContactItem';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Hide from '../components/Hide';
import TextSection from '../components/TextSection';

const GlobalStyles = createGlobalStyle`
  html {
    background-color: black;
  }
`;

const Information = ({ data: { pageData } }) => (
  <div>
    <GlobalStyles />
    <Helmet>
      <title>Information</title>
    </Helmet>
    <Banner invertColor />
    <Header activePageName="INFORMATION" invertColor rightText="CONTACT" />
    <Box
      css={{
        maxWidth: '1500px',
      }}
      mx="auto"
      mt={['25px', '30px', '70px']}
      px={[20, 30, 70]}
    >
      <TextSection invertColor title="About">
        <Text
          color="white"
          css={{ 'white-space': 'pre-wrap' }}
          dangerouslySetInnerHTML={{ __html: pageData.aboutHtml }}
          fontFamily="grot10"
          fontSize={['16px', '16px', '21px']}
          letterSpacing="-0.5px"
          lineHeight={['22px', '22px', '25px']}
        />
      </TextSection>
      <TextSection flexContainer id="content" invertColor title="Contact">
        {pageData.contacts.map((contact, index) => (
          <ContactItem
            contactsLength={pageData.contacts.length}
            email={contact.email}
            index={index}
            invertColor
            key={contact.email}
            name={contact.name}
            title={contact.title}
          />
        ))}
      </TextSection>
      <TextSection flexContainer invertColor title="Divine">
        <Box width={['100%', '100%', 1 / 2]} mb={['20px', '20px', 0]}>
          <Text
            color="white"
            css={{ 'white-space': 'pre-wrap' }}
            fontFamily="grot10"
            fontSize={['16px', '16px', '21px']}
            letterSpacing="-0.5px"
            lineHeight={['22px', '22px', '25px']}
            mb="20px"
          >
            {pageData.address}
          </Text>
          <Link
            color="white"
            css={{
              '&:hover': {
                textDecorationColor: 'transparent',
              },
              textDecoration: 'underline',
              textDecorationColor: 'white',
              transition: 'all 0.1s ease-in-out',
            }}
            href={`mailto:${pageData.contactEmail}`}
          >
            <Text
              color="white"
              fontFamily="grot10"
              fontSize={['16px', '16px', '21px']}
              letterSpacing="-0.5px"
              lineHeight={['22px', '22px', '25px']}
            >
              {pageData.contactEmail}
            </Text>
          </Link>
          {pageData.contactPhoneNumber && (
            <Link
              color="white"
              css={{
                '&:hover': {
                  textDecorationColor: 'transparent',
                },
                textDecoration: 'underline',
                textDecorationColor: 'white',
                transition: 'all 0.1s ease-in-out',
              }}
              href={`tel:${pageData.contactPhoneNumber.replace(/\s/g, '')}`}
            >
              <Text
                color="white"
                fontFamily="grot10"
                fontSize={['16px', '16px', '21px']}
                letterSpacing="-0.5px"
                lineHeight={['22px', '22px', '25px']}
              >
                {pageData.contactPhoneNumber}
              </Text>
            </Link>
          )}
        </Box>
        <Box width={['100%', '100%', 1 / 2]} mb={['20px', '20px', 0]}>
          {pageData.socialLinks.map((socialLink) => (
            <Link
              color="white"
              css={{
                '&:hover': {
                  textDecorationColor: 'transparent',
                },
                textDecoration: 'underline',
                textDecorationColor: 'white',
                transition: 'all 0.1s ease-in-out',
              }}
              href={socialLink.url}
              key={socialLink.name}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text
                color="white"
                fontFamily="grot10"
                fontSize={['16px', '16px', '21px']}
                letterSpacing="-0.5px"
                lineHeight={['22px', '22px', '25px']}
              >
                {socialLink.name}
              </Text>
            </Link>
          ))}
          <Text
            color="white"
            fontFamily="grot10"
            fontSize={['16px', '16px', '21px']}
            letterSpacing="-0.5px"
            lineHeight={['22px', '22px', '25px']}
            mt="20px"
          >
            Site by:{' '}
            <Hide display={['inline', 'inline', 'none']}>
              <Link
                color="white"
                css={{
                  '&:hover': {
                    textDecorationColor: 'transparent',
                  },
                  textDecoration: 'underline',
                  textDecorationColor: 'white',
                  transition: 'all 0.1s ease-in-out',
                }}
                href="https://antoinebarres.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                AB
              </Link>
            </Hide>
            <Hide display={['none', 'none', 'inline']}>
              <Link
                color="white"
                css={{
                  '&:hover': {
                    textDecorationColor: 'transparent',
                  },
                  textDecoration: 'underline',
                  textDecorationColor: 'white',
                  transition: 'all 0.1s ease-in-out',
                }}
                href="https://antoinebarres.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Antoine Barrès
              </Link>
            </Hide>
          </Text>
        </Box>
      </TextSection>
      <AnimatedContainer>
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          pb="25px"
          mt={[0, 0, '70px']}
        >
          {pageData.images.map((image, index) => (
            <Box
              key={image.id}
              width={[
                1,
                'calc(50% - 2.5px)',
                'calc(33.33333333333333% - 6.66px)',
              ]}
              mb={['5px', '5px', '10px']}
            >
              {index === 8 ? (
                <Hide display={['block', 'none', 'block']}>
                  <Img fluid={image.childImageSharp.fluid} />
                </Hide>
              ) : (
                <Img fluid={image.childImageSharp.fluid} />
              )}
            </Box>
          ))}
        </Flex>
      </AnimatedContainer>
    </Box>
    <Footer activePageName="INFORMATION" invertColor />
  </div>
);

Information.propTypes = {
  data: PropTypes.shape({
    pageData: PropTypes.shape({
      aboutHtml: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      contactEmail: PropTypes.string.isRequired,
      contactPhoneNumber: PropTypes.string,
      contacts: PropTypes.arrayOf(
        PropTypes.shape({
          email: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      socialLinks: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export default Information;

export const query = graphql`
  {
    pageData: informationPageJson {
      aboutHtml
      contacts {
        name
        title
        email
      }
      address
      contactEmail
      contactPhoneNumber
      socialLinks {
        name
        url
      }
      images {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
